// General Layout
export const HOME = {
    LAYOUT: "/",
    INDEX: {
        PATH: "/dashboard",
        NAME: "home.index",
        TITLE: "Reporting Tool",
        META: {
            breadcrumbs: [
                {
                    text: "Reporting Tool",
                    to: { name: "home.index" },
                },
            ],
        },
    },
    REPORTS: {
        PATH: "/reports",
        NAME: "reports.index",
        TITLE: "Reporting Tool",
        META: {
            breadcrumbs: [
                {
                    text: "Reporting Tool",
                    to: { name: "home.index" },
                },
            ],
        },
    },
    VIEW_REPORT: {
        PATH: "/reports/:id",
        NAME: "reports.view",
        TITLE: "View Report",
        META: {
            breadcrumbs: [
                {
                    text: "Reporting Tool",
                    to: { name: "home.index" },
                },
            ],
        },
    },
    VIEW_REPORT_HISTORY: {
        PATH: "/reports/:id/history",
        NAME: "reports.view-history",
        TITLE: "View Report History",
        META: {
            breadcrumbs: [
                {
                    text: "Reporting Tool",
                    to: { name: "home.index" },
                },
            ],
        },
    },
    CREATE_REPORT: {
        PATH: "/create-report",
        NAME: "reports.create",
        TITLE: "Create Report",
        META: {
            breadcrumbs: [
                {
                    text: "Reporting Tool",
                    to: { name: "home.index" },
                },
            ],
        },
    },
};

export const AUTH = {
    LAYOUT: "/",
    LANDING: {
        PATH: "/",
        NAME: "auth.landing",
        TITLE: "Reporting Tool",
        META: {},
    },
    LOGIN: {
        PATH: "/login",
        NAME: "auth.login",
        TITLE: "Reporting Tool",
        META: {},
    },
    SIGN_UP: {
        PATH: "/signup",
        NAME: "auth.signup",
        TITLE: "Reporting Tool",
        META: {},
    },
    FORGOT_PASSWORD: {
        PATH: "/forgot-password",
        NAME: "auth.forgot-password",
        TITLE: "Reporting Tool",
        META: {},
    },
    VERIFY_EMAIL: {
        PATH: "/verify",
        NAME: "auth.verify-email",
        TITLE: "Reporting Tool",
        META: {},
    },
};

export const REPORTS = {
    LAYOUT: "/reports",
};

export const SETTINGS = {
    LAYOUT: "/settings",
    INDEX: {
        PATH: "",
        NAME: "settings.index",
        TITLE: "Settings",
        META: {
            breadcrumbs: [
                {
                    text: "Reporting Tool",
                    to: { name: "home.index" },
                },
            ],
        },
    },
    PROFILE: {
        PATH: "profile",
        NAME: "settings.profile",
        TITLE: "Settings - Profile",
        META: {
            breadcrumbs: [
                {
                    text: "Reporting Tool",
                    to: { name: "home.index" },
                },
            ],
        },
    },
    BUSINESS: {
        PATH: "business",
        NAME: "settings.business",
        TITLE: "Settings - Business",
        META: {
            breadcrumbs: [
                {
                    text: "Reporting Tool",
                    to: { name: "home.index" },
                },
            ],
        },
    },
    USER_ACCESS: {
        PATH: "user-access",
        NAME: "settings.user-access",
        TITLE: "Settings - User Access",
        META: {
            breadcrumbs: [
                {
                    text: "Reporting Tool",
                    to: { name: "home.index" },
                },
            ],
        },
    },
};
